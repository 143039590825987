(function(ns) {

    ns.build = function (items, styleCode) {
        var stockItems = [];

        var coloursLength = items.length;
        for (var cIndex = 0; cIndex < coloursLength; cIndex++) {

            var colourVariant = items[cIndex];
            var colourVariantId = codesToId(styleCode, colourVariant.ColourCode);

            var sizesLength = colourVariant.ProductSkus.length;
            for (var sIndex = 0; sIndex < sizesLength; sIndex++) {

                var sizeVariant = colourVariant.ProductSkus[sIndex];
                var sizeVariantId = codesToId(styleCode, sizeVariant.SizeCode);

                stockItems.push(makeStockObject(colourVariantId, sizeVariantId, sizeVariant.StockLevel, sizeVariant.DueWeeks, sizeVariant.FinalPrice));

            }
        }

        return stockItems;
    };

    function codesToId(styleCode, variantCode) {
        return (styleCode + "-" + variantCode).toUpperCase().replace(" ", "-");
    };


    function makeStockObject(colourVariantId, sizeVariantId, stockLevel, dueWeeks, price) {
        var status;
        var level;
        switch (stockLevel) {
            case 1:
                status = "out-of-stock";
                level = "Out of stock";
                break;
            case 2:
                status = "in-stock";
                level = "Low stock";
                break;
            case 3:
                status = "back-order";
                level = "Stock due in " + dueWeeks + " week(s)";
                break;
            case 4:
                status = "in-stock";
                level = "In stock";
                break;
            default:
                status = "unknown";
                level = "unknown";
                break;
        }

        return {
            variants: [colourVariantId, sizeVariantId],
            status: status,
            level: level,
            value: {
                gross: price,
                net: price,
                tax: 0
            }
        };
    };

})(window.trackingstockbuilder = window.trackingstockbuilder || {});
(function(ns) {

    ns.build = function (items, styleCode) {
        var variants = [];
        var sizeVariantIds = [];

        var coloursLength = items.length;
        for (var cIndex = 0; cIndex < coloursLength; cIndex++) {

            var colourVariant = items[cIndex];

            variants.push({
                id: codesToId(styleCode, colourVariant.ColourCode),
                type: "colour",
                name: colourVariant.ColourCode,
                description: colourVariant.ColourDescription
            });

            var sizesLength = colourVariant.ProductSkus.length;
            for (var sIndex = 0; sIndex < sizesLength; sIndex++) {
                var sizeVariant = colourVariant.ProductSkus[sIndex];
                var sizeVariantId = codesToId(styleCode, sizeVariant.SizeCode);

                // check we havent captured size info from another colour
                var sizeToBeAdded = sizeVariantIds.indexOf(sizeVariantId) < 0;
                if (sizeToBeAdded) {
                    sizeVariantIds.push(sizeVariantId);
                    variants.push({
                        id: sizeVariantId,
                        type: "size",
                        name: sizeVariant.SizeCode,
                        description: sizeVariant.SizeCode
                    });
                }
            }
        }

        // return only unique items
        return variants;
    };

    function codesToId(styleCode, variantCode) {
        return (styleCode + "-" + variantCode).toUpperCase().replace(" ", "-");
    };


})(window.trackingvariantbuilder = window.trackingvariantbuilder || {});
(function(ns) {

    var filters;
    var filterIndex;
    var filterSetIndex;
    var search;

    ns.buildFromFilters = function(filterSets, resultsCount) {

        filters = [];
        filterIndex = 0;
        filterSetIndex = 0;
        search = { filters: [], results: resultsCount };

        var filterSetsLength = filterSets.length;
        for (var fIndex = 0; fIndex < filterSetsLength; fIndex++) {

            var filterSet = filterSets[fIndex];

            switch (filterSet.filterType) {
                case "Single":
                    setFilterItems(filterSet.items, filterSet.name); // TODO in v1.9 update to filterSet.key
                    break;
                case "List":
                    var filtersLength = filterSet.filters.length;
                    for (var index = 0; index < filtersLength; index++) {
                        var filter = filterSet.filters[index];
                        setFilterItems(filter.items, filter.name); // TODO in v1.9 update to filter.key
                    }
                    break;
                case "Tree":
                    setTreeFilterItems(filterSet.items, filterSet.name); // TODO in v1.9 update to filterSet.key
                break;
            }

        }

        return { filters: filters, search: search };
    };

    ns.buildFromProductColours = function (productColours, selectedColourCode, resultsCount) {

        filters = [];
        filterIndex = 0;
        filterSetIndex = 0;
        search = { filters: [] };

        var coloursLength = productColours.length;
        for (var i = 0; i < coloursLength; i++) {
            var colour = productColours[i];

            filters.push({
                id: filterIndex,
                type: "colour",
                name: colour.ColourDescription,
                position: 0
            });

            if (colour.ColourCode === selectedColourCode) {
                search.filters.push(filterIndex);
            }

            filterIndex++;
        }

        return { filters: filters, search: search };
    }

    function setFilterItems(items, type) {
        var itemsLength = items.length;
        for (var index = 0; index < itemsLength; index++) {
            var item = items[index];

            filters.push({
                id: filterIndex,
                type: type,
                name: item.text, // TODO in v1.9 update to item.key
                position: filterSetIndex
            });

            if (item.selected) {
                search.filters.push(filterIndex);
            }

            filterIndex++;
        }

        filterSetIndex++;
    }

    function setTreeFilterItems(items, type) {
        var itemsLength = items.length;
        for (var index = 0; index < itemsLength; index++) {
            var item = items[index];

            filters.push({
                id: filterIndex,
                type: type,
                name: item.text, // TODO in v1.9 update to item.key
                position: filterSetIndex
            });

            if (item.selected) {
                search.filters.push(filterIndex);
            }

            filterIndex++;

            var categoriesLength = item.categories.length;
            for (var cIndex = 0; cIndex < categoriesLength; cIndex++) {
                var category = item.categories[cIndex];

                filters.push({
                    id: filterIndex,
                    type: type + "-" + item.text, // TODO in v1.9 update to item.key
                    name: category.text,
                    position: filterSetIndex
                });

                if (category.selected) {
                    search.filters.push(filterIndex);
                }

                filterIndex++;
            }
        }
        
        filterSetIndex++;
    }

})(window.trackingproductfindingbuiler = window.trackingproductfindingbuiler || {});

var events = (function () {
    var topics = {};
    var hOp = topics.hasOwnProperty;

    return {
        subscribe: function (topic, listener) {
            if (!hOp.call(topics, topic)) topics[topic] = [];
            var index = topics[topic].push(listener) - 1;
            return {
                remove: function () {
                    delete topics[topic][index];
                }
            };
        },
        publish: function (topic, info) {
            if (!hOp.call(topics, topic)) return;
            topics[topic].forEach(function (item) {
                item(info != undefined ? info : {});
            });
        }
    };
})();
(function (ns) {

    // This class exists so that we can substitute the window.location for unit testing.

    ns.getUrlWithoutRoot = function () {
        return window.location.href.replace(ns.getHost(), "");
    };

    ns.getHost = function() {
        return window.location.protocol + "//" + window.location.host;
    };

})(window.trackingpage = window.trackingpage || {});
// to do - move this definition into GTM so DMPG have control of when screen fires.
(function (ns) {

    var standardTrackingRequirements = ["screen", "platform", "shoppingBag", "user"];
    var standardProductTrackingRequirements = standardTrackingRequirements.concat(["assets", "products", "productFinding"]);

    ns.get = function (pageTypeToCheck) {

        switch(pageTypeToCheck) {
            case window.trackingpagetypeenum.PRODUCT:
                return standardProductTrackingRequirements;
            case window.trackingpagetypeenum.PRODUCT_LISTING:
                return standardProductTrackingRequirements;
            default:
                return standardTrackingRequirements;
        }
    }

})(window.trackingrequirements = window.trackingrequirements || {});
(function (ns) {

    var requiredMessages = [];
    var onLoadSentMessages = [];
    var messagesToSend = [];

    var dataLayerSent = false;

    var pageType = function () {
        // get fresh everytime as pageType. PageType could be overridden from generic to specific in phase 2 
        if (window._digitalData && window._digitalData.screen && window._digitalData.screen.type)
            return window._digitalData.screen.type;
        return "unknown";
    }();

    ns.send = function (message, eventName) {

        // once it's sent it's sent - TODO review if this is needed
        if (dataLayerSent) {
            return;
        }

        //if (typeof message === "undefined") return;

        if (message) {
            messagesToSend.push(message);
        }

        requiredMessages = trackingrequirements.get(pageType);

        if (!requiredMessages) {
            // call self again without message at a later date
            window.Boden_Page_Common.defer('gtm', function () { ns.send() });
            return;
        }

        var recievedMessages = onLoadSentMessages.concat(messagesToSend).sort();

        if (!dataLayerSent && hasRequiredMessages(requiredMessages, recievedMessages)) {

            window._digitalData.event = eventName === undefined ? "dataLayer.load.auto.dataLayer.load" : eventName;

            pushToGtm(window._digitalData.event, messagesToSend);

            // only send dataLayer event once
            dataLayerSent = true;
        }
    };

    ns.remove = function (productToRemove) {
        window.dataLayer.push({
            "event": "product.interact.manual.click.removeFromBag",
            "product": productToRemove
        });
    };

    // TODO - do we need this????? This method can be called to resend entire digitalData in its on page state with another dataLayer.load event
    ns.newPageView = function (messageToResend, eventName) {
        dataLayerSent = false;
        //ns.send(messageToResend, eventName);
        if (typeof eventName !== "undefined") {
            pushToGtm(eventName, messagesToSend);
        }
    };
    // TODO - end

    ns.TestOnlyResetDataLayerSent = function () {
        messagesToSend = [];
        dataLayerSent = false;
    };

    ns.setOnLoadSentMessages = function (messsages) {
        if (!Array.isArray(messsages)) {
            return;
        }

        onLoadSentMessages = messsages;
        ns.send();
    }

    function pushToGtm(eventName, objNames) {

        var objectToPush = { event: eventName };

        objNames.forEach(function (objName) {
            objectToPush[objName] = window._digitalData[objName];
        });

        window.dataLayer.push(objectToPush);
    }

    function hasRequiredMessages(required, received) {
        if (!(Array.isArray(required) && Array.isArray(received)))
            return false;

        return required.filter(function (x) { return received.indexOf(x) < 0 }).length === 0;
    }


})(window.trackingsender = window.trackingsender || {});
(function (ns) {

    ns.addProduct = function (product) {
        events.publish("tracking.assets.product", product);
    };

    ns.addProductFromPDP = function (pdpProductObj) {

        var mappedProduct =
        {
            styleCode: pdpProductObj.StyleCode.toLowerCase(),
            name: pdpProductObj.StyleDescription,
            description: pdpProductObj.StyleFullDescription,
            variants: trackingvariantbuilder.build(pdpProductObj.ProductColours, pdpProductObj.StyleCode),
            stock: trackingstockbuilder.build(pdpProductObj.ProductColours, pdpProductObj.StyleCode)
        };

        events.publish("tracking.assets.product", mappedProduct );
    };

    ns.addProductsFromPLP = function (products) {
        var productDictionary = {};
        var productColourArray = getArrayOfProductColours(products);


        for (var i = 0; i < products.length; i++) {
            var product = products[i];

            // Comes in two formats - grouped by model or as seperate colour
            var styleCode = product.styleCode;
            if (product.id && product.id.styleCode && product.id.colourCode) {
                styleCode = product.id.styleCode;
            }

            // Add product
            if (typeof productDictionary[styleCode] === "undefined") {
                productDictionary[styleCode]= {
                    styleCode: styleCode,
                    name: product.title,
                    variants: []
                };
            }

            // Add colour variants
            if (product.coloursList) {
                // grouped by model - add each variant
                var coloursListLength = product.coloursList.length;
                for (var j = 0; j < coloursListLength; j++) {
                    var colour = product.coloursList[j];
                    
                    productDictionary[styleCode].variants.push({
                        id: codesToId(styleCode, colour.colourCode),
                        type: "colour",
                        name: colour.colourCode
                        // description - not available
                    });

                    
                }
            } else if (product.colour) {
                // add single colour variant
                productDictionary[styleCode].variants.push({
                    id: codesToId(styleCode, product.colour.colourCode),
                    type: "colour",
                    name: product.colour.colourCode,
                    description: product.colour.description
                });
            }
        }
        
        // map to regular index array
        var mappedProducts = [];
        for (var p in productDictionary) {
            if (productDictionary.hasOwnProperty(p)) {
                mappedProducts.push(productDictionary[p]);
            }
        }

        events.publish("tracking.assets.products", mappedProducts);
        events.publish("tracking.assets.productcolourarray", productColourArray);
    };

    ns.addCreative = function (creative) {
        events.publish("tracking.assets.creative", creative);
    };

    ns.addArticle = function (article) {
        events.publish("tracking.assets.article", article);
    };

    ns.addVideo = function (video) {
        events.publish("tracking.assets.video", video);
    };

    function codesToId(styleCode, variantCode) {
        return (styleCode + "-" + variantCode).toUpperCase().replace(" ", "-");
    };

    function getArrayOfProductColours(products) {
        var productColourArray = [];

        for (var i = 0; i < products.length; i++) {
            if (products[i].coloursList !== undefined && products[i].coloursList.length > 0) {
                for (var index = 0; index < products[i].coloursList.length; index++) {
                    productColourArray.push(codesToId(products[i].styleCode, products[i].coloursList[index].colourCode));
                }
            }
            else if (products[i].id !== undefined) {
                productColourArray.push(codesToId(products[i].id.styleCode, products[i].id.colourCode));
            }
        }
        return productColourArray;
    }

})(window.trackingassets = window.trackingassets || {});
(function(ns) {

    var breadcrumbArray = [];

    ns.add = function(breadcrumb) {
        breadcrumbArray.push(breadcrumb);
    };

    ns.publish = function() {
        events.publish("tracking.breadcrumbs", breadcrumbArray);
    };

})(window.trackingbreadcrumb = window.trackingbreadcrumb || {});
(function (ns) {

    ns.addEvent = function (event) {
        window._digitalData.element = event.element || {};
        window.dataLayer.push(event);
    };

    ns.productFindingEventPDP = function (values) {
        trackingproductfinding.updateProductFindingFromPDP(values.colour);
        ns.addEvent({
            event: "load.manual.apply.filter",
            productFinding: window._digitalData.productFinding
        });
    };

    ns.productFindingEventPLP = function () {
        ns.addEvent({
            event: "load.manual.apply.filter",
            productFinding: window._digitalData.productFinding
        });
    };

    ns.accordionChange = function (hierarchyToAdd) {
        // This method updates the tracking category value for the accordion which 
        // has 3 sections (ContactDetailsEdit, AddressEdit, Payment) but we need to 
        // change the names for each section for tracking purposes..

        if (window._digitalData.screen.hierarchy.length > 2) {
            window._digitalData.screen.hierarchy.pop();
        }

        var category = "";
        if (hierarchyToAdd === "ContactDetailsEdit") {
            hierarchyToAdd = "contact-details";
            category = "home.checkout.contact-details";
        }
        if (hierarchyToAdd === "AddressEdit") {
            hierarchyToAdd = "address-details";
            category = "home.checkout.address-details";
        }
        if (hierarchyToAdd === "Payment") {
            hierarchyToAdd = "payment";
            category = "home.checkout.payment";
        }

        trackingshoppingbag.updateScreenCategory(category);
        window._digitalData.screen.hierarchy.push(hierarchyToAdd);
        window._digitalData.screen.url = trackingpage.getUrlWithoutRoot();

        trackingsender.send("screen");
        trackingsender.newPageView();
    };

})(window.trackingevents = window.trackingevents || {});
// Javascript enum for tracking the page type

var trackingpagetypeenum = {
    HOMEPAGE: "homepage",
    CATEGORY: "category",
    PRODUCT_LISTING: "product-listing",
    PRODUCT: "product-details",
    SEGMENT_HOME: "segment-home",
    SEARCH_RESULTS: "search-results",
    ARTICLE_INDEX: "article-index",
    ARTICLE: "article",
    SHOPPINGBAG: "shopping-bag",
    CHECKOUT: "checkout",
    ACCOUNT: "account",
    GENERAL_INFO: "general-info",
    MULTI_PURPOSE: "multi-purpose",
    UNKNOWN: "unknown"
};
(function (ns) {

    var hasCount = false;
    var hasFilters = false;

    var resultsCount;
    var selectedFilters;
    var filters;

    ns.addProductFindingFromPDP = function (pdpFindingObj) {
        events.publish("tracking.productfinding", trackingproductfindingbuiler.buildFromProductColours(pdpFindingObj.productColours, pdpFindingObj.selectedColourCode, pdpFindingObj.resultsCount));
    };

    ns.updateProductFindingFromPDP = function(selectedName) {

        var productFinding = window._digitalData.productFinding;

        productFinding = productFinding || {};
        productFinding.search = productFinding.search || {};
        productFinding.filters = productFinding.filters || [];

        productFinding.search.filters = [];

        var matchingFilter = productFinding.filters.find(function(element) {
            return element.name === selectedName;
        });

        if (matchingFilter) {
            productFinding.search.filters = [matchingFilter.id];
        }

        events.publish("tracking.productfinding", productFinding);
    };

    ns.addResultsCount = function(count) {
        resultsCount = count;
        hasCount = true;

        initialPublish();
    };

    ns.addResultsCountFromShopByCategory = function (count) {
        events.publish("tracking.productfinding", {
            search: { resultsCount: count },
            filters: {}
        });
    };

    ns.addFilters = function (filterSets) {
        var buildFilters = trackingproductfindingbuiler.buildFromFilters(filterSets);
        selectedFilters = buildFilters.search.filters;
        filters = buildFilters.filters;
        hasFilters = true;

        initialPublish();
    };

    function initialPublish() {

        // only publish if both parts have been updated
        if (!(hasCount && hasFilters))
            return;

        events.publish("tracking.productfinding", {
            search: { resultsCount: resultsCount, filters: selectedFilters },
            filters: { filters: filters }
        });
    };

})(window.trackingproductfinding = window.trackingproductfinding || {});
(function (ns) {

    ns.addProducts = function (products) {
        events.publish("tracking.products", products);
    };

    ns.remove = function(product) {
        events.publish("tracking.remove", product);
    };

})(window.trackingproducts = window.trackingproducts || {});
(function(ns) {

    var shoppingBag = {
        lines: [],
        value: { tax: 0, gross: 0, net: 0 },
        currency: ""
    };

    ns.setCurrency = function(value) {
        shoppingBag.currency = value;
    }

    ns.overrideShoppingBag = function (bag) {
        shoppingBag = bag;
        ns.publish();
    };

    ns.updateScreenCategory = function (category) {
        events.publish("tracking.screen.category", category);
    };

    ns.publish = function() {
        events.publish("tracking.shopping.bag", shoppingBag);
    };

})(window.trackingshoppingbag = window.trackingshoppingbag || {});
(function(ns) {

    var user = {};

    ns.addUser = function(userToAdd) {
        user = userToAdd;
    };

    ns.publish = function() {
        events.publish("tracking.user", user);
    };

})(window.trackinguser = window.trackinguser || {});
(function () {
    events.subscribe("tracking.shopping.bag", function (shoppingBag) {
        window._digitalData.shoppingBag = shoppingBag;

        trackingsender.send("shoppingBag");
    });

    events.subscribe("tracking.breadcrumbs", function (breadcrumbItems) {
        window._digitalData.screen.hierarchy = breadcrumbItems;
    });

    events.subscribe("tracking.transaction", function (transaction) {
        window._digitalData.transaction = transaction;
    });

    events.subscribe("tracking.screen.category", function (category) {
        window._digitalData.screen.category = category;
    });

    events.subscribe("tracking.user", function (user) {
        window._digitalData.user = user;

        trackingsender.send("user");
    });

    events.subscribe("tracking.remove", function (productToRemove) {
        trackingsender.remove(productToRemove);
    });

    events.subscribe("tracking.products", function (products) {
        window._digitalData.products = products;

        trackingsender.send("products");
    });

    events.subscribe("tracking.type", function (type) {
        window._digitalData.screen.type = type;
    });

    events.subscribe("tracking.assets.product", function (product) {
        initialiseAssets();

        window._digitalData.assets.product = [product];

        trackingsender.send("assets");
    });

    events.subscribe("tracking.assets.products", function (products) {
        initialiseAssets();

        window._digitalData.assets.product = products;

        trackingsender.send("assets");
    });

    events.subscribe("tracking.assets.productcolourarray", function (productColourArray) {
        window._digitalData.product_colour = productColourArray;

        trackingsender.send("product_colour");
    });

    events.subscribe("tracking.productfinding", function (productFinding) {
        window._digitalData.productFinding = {
            search: productFinding.search,
            filters: productFinding.filters.filters
        };

        trackingsender.send("productFinding");
    });

    events.subscribe("tracking.assets.creative", function (creative) {
        initialiseAssets();

        window._digitalData.assets.creative.push(creative);

        trackingsender.send("assets");
    });

    events.subscribe("tracking.assets.article", function (article) {
        initialiseAssets();

        window._digitalData.assets.article.push(article);

        trackingsender.send("assets");
    });

    events.subscribe("tracking.assets.video", function (video) {
        initialiseAssets();

        window._digitalData.assets.video.push(video);

        trackingsender.send("assets");
    });

    events.subscribe("tracking.updatedatalayer", function () {
        trackingsender.newPageView("screen", "screen.load.auto.datalayer.load");
    });

    function initialiseAssets() {
        if (typeof (window._digitalData.assets) === "undefined") {
            window._digitalData.assets = {
                product: [],
                creative: [],
                article: [],
                video: []
            };
        }
    };

})();
(function () {
    window.Boden_Page_Common = window.Boden_Page_Common || {};
    window.Boden_Page_Common.defer = defer || function () { };
    var maxlimit = 200;
    var satelliteCheckCount = 0;
    var trackingCheckCount = 0;
    var gtmCheckCount = 0;
    var offerPriceTestCheckCount = 0;
    var ddscreenloadCheckCount = 0;

    function defer(objectType, method) {
        if (!satelliteCheckCount) {
            satelliteCheckCount = 0;
        }
        if (!trackingCheckCount) {
            trackingCheckCount = 0;
        }
        if (!gtmCheckCount) {
            gtmCheckCount = 0;
        }
        switch (objectType) {
            case 'jquery':
                if (window.jQuery) {
                    method();
                }
                else {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                }
                break;
            case 'clientcontrol':
                if (window.ClientControl) {
                    method();
                }
                else {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                }
                break;
            case '_satellite':
                if (window._satellite) {
                    method();
                }
                else if (!window._satellite && satelliteCheckCount <= maxlimit) {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                    satelliteCheckCount++;
                }
                break;
            case 'tracking':
                // TODO change this when simplify name space
                var isTrackingLoaded = window.trackinguser && window.trackingshoppingbag && window.trackingsender && window.trackingbreadcrumb && window.trackingrequirements;
                if (isTrackingLoaded) {
                    method();
                }
                else if (trackingCheckCount <= maxlimit) {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                    trackingCheckCount++;
                }
                break;
            case 'gtm':
                if (window.google_tag_manager) {
                    method();
                }
                else if (!window.google_tag_manager && gtmCheckCount <= maxlimit) {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                    gtmCheckCount++;
                }
                break;
            case 'ddscreenload':
                if ((window._digitalData.screen.hierarchy || []).length > 0) {
                    method();
                }
                else if ((window._digitalData.screen.hierarchy || []).length === 0 && ddscreenloadCheckCount <= maxlimit) {
                    setTimeout(function () { window.Boden_Page_Common.defer(objectType, method) }, 50);
                    ddscreenloadCheckCount++;
                }
                break;
            }

        var offerPriceTestCheckInterval = 0;
        this.apply = function (objectType, method) {
            var currentInstance = this;
            if (window.offerTestLoaded) {
                method();
            }
            else if (!window.offerTestLoaded && offerPriceTestCheckInterval <= maxlimit) {
                setTimeout(function () { currentInstance.apply(objectType, method) }, 50);
                offerPriceTestCheckInterval++;
            }
            else if (!window.offerTestLoaded && offerPriceTestCheckInterval >= maxlimit) {
                window.offerTestLoaded = true;
                window.offerTestShowStrikeThrough = true;
                method();
            }
        }
    }

    //required for csa inline code to run
    window.readCookie = window.readCookie || function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
})();